import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import dayjs from 'dayjs';
import { toast, ToastContainer } from 'react-toastify';
import BetsTable from '../../components/BetsTable/BetsTable';
import SourceControls from '../../components/SourceControls/SourceControls';
import Separator from '../../components/Separator/Separator';
import style from './Referral.module.css';
import useReferredBets from '../../hooks/bets/useReferredBets';
import useBetFeed from '../../hooks/bets/useBetFeed';
import { ClaimedIcon } from '../../components/Icon/Icons';
import useGetClaimedBets from '../../hooks/bets/useGetClaimedBets';
import useClaimedNotificationFeed from '../../hooks/bets/useClaimedNotificationFeed';
import useReferredNotificationFeed from '../../hooks/bets/useReferredNotificationFeed';
import 'react-toastify/dist/ReactToastify.css';
import { ClaimedRowChildCurry } from '../../components/ClaimedRowChild/ClaimedRowChild';
import { UnclaimedRowChildCurry } from '../../components/UnclaimedRowChild/UnclaimedRowChild';
import { PriceSetProvider } from '../../contexts/price/priceSetContext';
import useUser from '../../hooks/user/useUser';

function genObjFromPair(k, v) {
  const obj = {};
  obj[k] = v;
  return obj;
}

function Referral() {
  const { user } = useUser();

  const [criteria, setCriteria] = useState({
    sources: ['empty'],
    event: '',
    event_types: [],
    selection: '',
    status: 'pending',
    stake: 0,
    event_location: '',
    client: '',
    tags: [],
    start_date: dayjs().subtract(1, 'day').format(), // end_date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  });

  const [getClaimedBetsCriteria, setGetClaimedBetsCriteria] = useState({
    sources: [],
    event: '',
    event_types: [],
    selection: '',
    status: 'pending',
    stake: 0,
    event_location: '',
    client: '',
    tags: [],
    start_date: '', // end_date: '',
  });

  const [fireBets, setFireBets] = useState(true);
  const [fireGetClaimedBets, setFireGetClaimedBets] = useState(true);

  const runBetsQueryID = useRef(0);
  const runClaimedBetsQueryID = useRef(0);
  const { bets, loading } = useReferredBets(criteria, fireBets);
  const { bets: getClaimedBets, loading: loadingGetClaimedBets } =
    useGetClaimedBets(getClaimedBetsCriteria, fireGetClaimedBets);
  const [isFeedOn, setIsFeedOn] = useState(true);

  const setCriteriaWithTime = useCallback((update) => {
    setCriteria((prev) => ({
      ...prev,
      ...update,
    }));
  }, []);

  const onSourceControlChange = useCallback(
    ({ type, sources, tags }) => {
      setCriteriaWithTime({ event_types: type, sources, tags });
    },
    [setCriteriaWithTime]
  );

  const onTableControlChange = (k, v) => {
    if (criteria[k] !== undefined) setCriteriaWithTime(genObjFromPair(k, v));
  };

  const onFeedControlChange = (val) => {
    setIsFeedOn((prev) => {
      if (!prev && val) {
        setFireBets(true);
      }

      return val;
    });
  };

  const { message } = useBetFeed(criteria, isFeedOn && true);
  const [consolidatedBets, setConsolidatedBets] = useState([]);
  const [claimedBets, setClaimedBets] = useState([]);

  useEffect(() => {
    setClaimedBets(getClaimedBets);
  }, [getClaimedBets]);

  useEffect(() => {
    setConsolidatedBets(bets);
  }, [bets]);
  useEffect(() => {
    if (!!message)
      setConsolidatedBets((prev) => {
        const cb = [message, ...prev];

        // sort cb on time and take one element off of top if its over 100
        if (cb.length > 100) cb.pop();

        return cb;
      });
  }, [message]);

  useEffect(() => {
    if (loading) setFireBets(false);
  }, [loading, setFireBets]);

  useEffect(() => {
    if (loadingGetClaimedBets) setFireGetClaimedBets(false);
  }, [loading, setFireGetClaimedBets]);

  // if page number changes, update criteria with new page number
  useEffect(() => {
    setCriteriaWithTime({
      paging: { page_number: 1, page_size: 100 },
    });
  }, [1, setCriteriaWithTime]);

  // fire bets query
  useEffect(() => {
    runBetsQueryID.current = setTimeout(() => {
      setFireBets(true);
    }, 500);

    return () => {
      clearTimeout(runBetsQueryID.current);
    };
  }, [criteria]);

  useEffect(() => {
    runClaimedBetsQueryID.current = setTimeout(() => {
      setFireGetClaimedBets(true);
    }, 500);

    return () => {
      clearTimeout(runClaimedBetsQueryID.current);
    };
  }, [setGetClaimedBetsCriteria]);

  useClaimedNotificationFeed(
    true,
    (bet, email) => {
      if (email === user.email)
        setClaimedBets((prev) => [...(prev ?? []), bet]);
    },
    (bet) => {
      setClaimedBets((prev) => (prev ?? []).filter((b) => b.id !== bet.id));
    }
  );

  useReferredNotificationFeed(true, (bet) => {
    setConsolidatedBets((prev) => prev.filter((b) => b.id !== bet.id));
  });

  const unclaimedRowChildTemplate = useMemo(
    () =>
      UnclaimedRowChildCurry((e) => toast.error(<span>{e.toString()}</span>)),
    []
  );

  const claimedRowChildTemplate = useMemo(
    () => ClaimedRowChildCurry((e) => toast.error(<span>{e.toString()}</span>)),
    []
  );

  return (
    <PriceSetProvider
      bets={claimedBets}
      purge={!loadingGetClaimedBets && claimedBets.length !== 0}
    >
      <div className={style.container}>
        <SourceControls
          onChange={onSourceControlChange}
          onFeedControlChange={onFeedControlChange}
          feedControlEnabled={false}
          page="referred"
        />
        <Separator />
        <BetsTable
          tableContainerClassName={style['table-container']}
          RowChild={unclaimedRowChildTemplate}
          tbodyClassName={style['table-body']}
          bets={consolidatedBets}
          maxSize={100}
          onChange={onTableControlChange}
          placeholder={
            <div className={style['table-placeholder']}>No Results Found.</div>
          }
          searchEnabled
          page="referred"
        />
        <div className={style['claimed-header']}>
          <ClaimedIcon />
          Claimed Bets
          <label className={style['claimed-counter']}>
            {claimedBets && claimedBets.length > 0 ? claimedBets.length : 0}
          </label>
        </div>

        <BetsTable
          tableContainerClassName={style['table-container']}
          tbodyClassName={style['table-body']}
          RowChild={claimedRowChildTemplate}
          bets={claimedBets}
          onChange={onTableControlChange}
          placeholder={
            <div className={style['table-placeholder']}>No Results Found.</div>
          }
          searchEnabled={false}
        />
        <ToastContainer
          position="top-right"
          autoClose={60000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    </PriceSetProvider>
  );
}

export default Referral;
