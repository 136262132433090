import React, { useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/auth/useAuth';
import Input from '../../components/Input/Input';
import Button, { COLOUR } from '../../components/Button/Button';

import style from './PasswordReset.module.css';
import { ICONS } from '../../components/Icon/Icon';

function PasswordReset() {
  const { resetPassword } = useAuth();
  const [email, setEmail] = useState('');

  const onResetPassword = (e) => {
    e.preventDefault();
    resetPassword(email);
  };

  return (
    <div className={style.container}>
      <div className={style.card}>
        <div className={style.form}>
          <span className={style['form-title']}>Reset Password</span>

          <span className={style['form-intro']}>
            Please enter your email. If we find your account we will email you
            the link to reset the password.
          </span>

          <span className={style['form-label']}>Email</span>
          <Input
            leftIcon={ICONS.email}
            className={style['form-input']}
            onChange={(ev) => setEmail(ev.target.value)}
          />

          <Button
            className={clsx(style.btn)}
            colour={COLOUR.black}
            onClick={onResetPassword}
          >
            Reset Password
          </Button>

          <Link to="/login">Go back to Login</Link>
        </div>
      </div>
    </div>
  );
}

export default PasswordReset;
