import React from 'react';
import * as IconList from './Icons';

export const ICONS = {
  logout: 'Logout',
  downArrow: 'DownArrow',
  tick: 'Tick',
  tickSmall: 'TickSmall',
  tickLarge: 'TickLarge',
  blackTick: 'BlackTick',
  leftArrow: 'LeftArrow',
  rightArrow: 'RightArrow',
  downwardPointer: 'DownwardPointer',
  pause: 'Pause',
  play: 'Play',
  search: 'Search',
  email: 'Email',
  showPassword: 'ShowPassword',
  doubleChevron: 'DoubleChevron',
  claimedClose: 'ClaimedClose',
  claimedFullscreen: 'ClaimedFullscreen',
  claimedNonFullscreen: 'ClaimedNonFullscreen',
  claimedIcon: 'ClaimedIcon',
  title: 'Title',
  asc: 'Asc',
  desc: 'Desc',
  volumeHigh: 'VolumeHigh',
  volumeMute: 'VolumeMute',
};

/**
 *
 * @param {string} name
 * @return {JSX.Element}
 * @constructor
 */
export default function Icon({ name }) {
  const SelectedIcon = IconList[name];

  return <SelectedIcon />;
}
