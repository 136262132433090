import React from 'react';
import clsx from 'clsx';
import styles from './PagingFooter.module.css';
import Button, { COLOUR } from '../Button/Button';
import Icon, { ICONS } from '../Icon/Icon';
import { DOTS, usePagination } from '../../hooks/pagination/usePagination';

function PagingFooter({
  onPageChange = (p, e) => {
    console.log(`pageNumber ${p} and event ${e || {}}`);
  },
  totalCount,
  siblingCount = 1,
  currentPage,
}) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
  });

  if (currentPage === 0 || paginationRange.length < 1) {
    return null;
  }

  const lastPage = paginationRange[paginationRange.length - 1];

  const onFirstPage = (e) => {
    onPageChange(1, e);
  };
  const onLastPage = (e) => {
    onPageChange(totalCount, e);
  };

  const onNext = (e) => {
    if (currentPage < totalCount) {
      onPageChange(currentPage + 1, e);
    } else onPageChange(currentPage, e);
  };

  const onPrevious = (e) => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1, e);
    } else onPageChange(currentPage, e);
  };

  return (
    <div className={clsx(styles.footer, styles.container)}>
      <Button onClick={onFirstPage}>
        <Icon name={ICONS.doubleChevron} />
      </Button>
      <Button onClick={onPrevious}>
        <Icon name={ICONS.leftArrow} />
      </Button>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return <span key={pageNumber}>&#8230;</span>;
        }

        return (
          <Button
            key={pageNumber}
            colour={pageNumber === currentPage ? COLOUR.black : COLOUR.white}
            onClick={(ev) => {
              onPageChange(pageNumber, ev);
            }}
            className={styles['page-btn']}
          >
            {pageNumber}
          </Button>
        );
      })}
      <Button
        onClick={onNext}
        className={clsx({ disabled: currentPage === lastPage })}
      >
        <Icon name={ICONS.rightArrow} />
      </Button>
      <Button
        onClick={onLastPage}
        className={styles['double-chevron-transform']}
      >
        <Icon name={ICONS.doubleChevron} />
      </Button>
    </div>
  );
}

export default PagingFooter;
