import React, { useMemo } from 'react';
import clsx from 'clsx';
import style from './Checkbox.module.css';
import Icon, { ICONS } from '../Icon/Icon';

/**
 * @param {'on' | 'off'} state
 * @param {string} className
 * @param {'sm' | 'lg'} size
 */
export default function Checkbox({ state, className, size }) {
  const tick = useMemo(() => {
    switch (size) {
      case 'sm':
        return <Icon name={ICONS.tickSmall} />;
      case 'lg':
        return <Icon name={ICONS.tickLarge} />;
      default:
        throw Error('Invalid checkbox size');
    }
  }, []);

  const config = useMemo(() => {
    const obj = {
      className: '',
      tick: <div />,
    };

    switch (state) {
      case 'on':
        obj.tick = tick;
        obj.className = clsx(style['tick-box'], style['tick-box-on']);
        break;

      case 'off':
        obj.className = style['tick-box'];
        break;

      default:
        throw new Error('Invalid Checkbox State');
    }

    obj.className = clsx(obj.className, style[size], className);

    return obj;
  }, [state, className, tick]);

  return <span className={config.className}>{config.tick}</span>;
}
