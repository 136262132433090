import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useAuth from '../../hooks/auth/useAuth';
import useRequest from '../../hooks/useRequest';
import { GET_CURRENT_USER_URL, SAVE_PREFERENCES_URL } from './const';

export const UserContext = createContext({
  user: undefined,
  updateRemotePreferences: undefined,
  localPreferences: {},
  updateLocalPreferences: {},
});

export function UserProvider({ children }) {
  const { isSignedIn } = useAuth();
  const { apiRequest } = useRequest();

  const [user, setUser] = useState(undefined);
  const [preferences, setPreferences] = useState({});
  const [localPreferences, setLocalPreferences] = useState({});

  const runRequestId = useRef(0);

  const [isInitialized, setIsInitialized] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (!isSignedIn || isUpdated) return;

    (async () => {
      const res = await apiRequest(GET_CURRENT_USER_URL, 'POST', {});

      const { user: currentUser } = await res.json();

      setUser(currentUser ?? {});
      setIsInitialized(true);
      setIsUpdated(true);
    })();
  }, [isSignedIn, apiRequest, isUpdated]);

  const currentPermissions = useMemo(() => {
    const defaultPermissions = {
      sources: ['all'],
      pages: ['all'],
    };

    const userPermissions = JSON.parse(user?.permissions || '{}');

    return { ...defaultPermissions, ...userPermissions };
  }, [user]);

  const checkPermissions = useCallback(
    (type, checkFor) => {
      if (!isInitialized) return false;

      if (type === 'sources') {
        const sources = currentPermissions?.sources || [];
        return sources.includes('all') || sources.includes(checkFor);
      }

      if (type === 'pages') {
        const pages = currentPermissions?.pages || [];
        return pages.includes('all') || pages.includes(checkFor);
      }

      return false;
    },
    [isInitialized, currentPermissions]
  );

  const currentPreferences = useMemo(
    () => JSON.parse(user?.preferences || '{}'),
    [user]
  );

  const updateRemotePreferences = useCallback(
    (page, update) => {
      if (!isInitialized) return;

      setPreferences((prev) => {
        const newPreferences = {
          ...currentPreferences,
          ...prev,
        };

        newPreferences[page] = {
          ...(newPreferences[page] ?? {}),
          ...(update ?? {}),
        };

        return { ...newPreferences };
      });
    },
    [isInitialized, currentPreferences]
  );

  const updateLocalPreferences = useCallback((page, update) => {
    setLocalPreferences((prev) => {
      const newLocalPreferences = prev;

      newLocalPreferences[page] = {
        ...newLocalPreferences[page],
        ...update,
      };

      return { ...newLocalPreferences };
    });
  }, []);

  useEffect(() => {
    if (!isInitialized) return () => {};

    runRequestId.current = setTimeout(() => {
      (async () => {
        await apiRequest(SAVE_PREFERENCES_URL, 'POST', {
          preferences: JSON.stringify(preferences),
        });

        setIsUpdated(false);
      })();
    }, 500);

    return () => {
      clearTimeout(runRequestId.current);
    };
  }, [apiRequest, preferences, isInitialized]);

  const value = useMemo(
    () => ({
      user,
      checkPermissions,
      localPreferences,
      updateLocalPreferences,
      updateRemotePreferences,
    }),
    [user, updateRemotePreferences]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
