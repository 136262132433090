import React, { createContext, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  browserLocalPersistence,
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { env } from '../../utils/env';

const firebaseConfig = {
  apiKey: env.REACT_APP_AUTH_API_KEY, // 'AIzaSyCZqYSscHMoVN2QL97Xekt1TTTWEo96NvM',
  authDomain: env.REACT_APP_AUTH_DOMAIN, // 'grp-uat-au.firebaseapp.com',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.setPersistence(browserLocalPersistence).then();
const gProvider = new GoogleAuthProvider();

export const AuthContext = createContext({
  logOut: () => {},
  logIn: () => {},
  getToken: () => {},
  logInWithGoogle: () => {},
  isValid: () => {},
  isSignedIn: false,
  email: '',
  user: {},
  errorMessage: '',
  currentUser: () => {},
});

export function AuthProvider({ children }) {
  const [user, setUser] = useState({});
  const [email, setEmail] = useState('');
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const resetPassword = useCallback(
    (e) => {
      if (e.includes('@betmakers.com')) {
        return alert('Please use your SSO to login to GRP.');
      }

      sendPasswordResetEmail(auth, e)
        .then(async () => {
          alert('Password reset email sent!');
          navigate('/login');
        })
        .catch((error) => {
          setErrorMessage(error.message);
          alert(
            'Invalid email or user not found. Please enter a valid email address.'
          );
        });

      return null;
    },
    [navigate]
  );

  const logIn = useCallback(
    (e, password) => {
      signInWithEmailAndPassword(auth, e, password)
        .then(async (userCredential) => {
          setUser(userCredential.user);
          setEmail(userCredential.user.email);
          setIsSignedIn(true);
          setErrorMessage('');
          navigate('/');
        })
        .catch((error) => {
          const errorCode = error.code;
          setErrorMessage(error.message);
          console.log(errorCode, error.message);
        });
    },
    [navigate]
  );

  const getToken = useCallback(
    async () =>
      user && typeof user.getIdToken === 'function'
        ? user.getIdToken()
        : undefined,
    [user]
  );

  const logInWithGoogle = () => {
    signInWithPopup(auth, gProvider)
      .then(async (userCredential) => {
        setUser(userCredential.user);
        setEmail(userCredential.user.email);
        setIsSignedIn(true);
        setErrorMessage('');
        navigate('/');
      })
      .catch((err) => {
        const errorCode = err.code;
        const credential = GoogleAuthProvider.credentialFromError(err);
        console.log(errorCode, err.message, err.customData, credential, err);
        setErrorMessage(errorMessage);
      });
  };

  const currentUser = useCallback(() => {
    // eslint-disable-next-line no-shadow
    const [user, loading, error] = useAuthState(auth);
    if (user) {
      setIsSignedIn(true);
      setUser(user);
    }
    return { user, loading, error };
  }, [auth]);

  const logOut = useCallback(() => {
    console.log('logging out');
    signOut(auth)
      .then(async () => {
        setUser({});
        setIsSignedIn(false);
        window.location.href = '/'; // Just reset everything.
      })
      .catch((err) => {
        console.log('signout error: ', err);
      });
  }, [navigate]);

  const value = useMemo(
    () => ({
      resetPassword,
      logIn,
      logInWithGoogle,
      logOut,
      getToken,
      isSignedIn,
      email,
      user,
      errorMessage,
      currentUser,
    }),
    [
      resetPassword,
      logIn,
      logInWithGoogle,
      logOut,
      getToken,
      isSignedIn,
      email,
      user,
      errorMessage,
      currentUser,
    ]
  );
  return (
    <AuthContext.Provider
      /* eslint-disable-next-line react/jsx-no-constructed-context-values */
      value={value}
    >
      {children}
    </AuthContext.Provider>
  );
}
