import { useEffect, useState } from 'react';
import { env } from '../../utils/env';
import useRequest from '../useRequest';

const url = new URL(
  '/srv.action.Action/GetClientComments',
  env.REACT_APP_PUBLIC_API_URL || 'http://localhost:8087'
);

export default function useClientComments({ client_id: clientId, source }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [comments, setComments] = useState([]);

  const { apiRequest } = useRequest();

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const res = await apiRequest(url, 'POST', {
          client: {
            client_id: clientId,
            source,
          },
        });

        const { ok, comments: rcomments, message } = await res.json();

        if (!ok) setError(new Error(message ?? ''));
        else setComments(rcomments ?? []);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [apiRequest]);

  return {
    loading,
    error,
    comments,
  };
}
