import { useCallback } from 'react';
import useRequest from '../useRequest';
import { env } from '../../utils/env';

const url = new URL(
  '/srv.action.Action/AcceptPartial',
  env.REACT_APP_PUBLIC_API_URL || 'http://localhost:8087'
);

export class AcceptPartialRequest {
  constructor(betId, source, newStake) {
    this.betId = betId;
    this.source = source;
    this.newStake = newStake;
  }

  intoPayload() {
    return {
      bet: {
        betId: this.betId,
        source: this.source,
      },
      newStake: this.newStake,
    };
  }
}
export default function useAcceptPartialBet() {
  const { apiRequest } = useRequest();

  const acceptPartial = useCallback(
    (request, onSuccess = undefined, onError = undefined) => {
      (async () => {
        try {
          const res = await apiRequest(url, 'POST', request.intoPayload());

          const data = await res.json();

          if (res.status !== 200 || !data.ok)
            onError?.(new Error(data.message));
          else onSuccess?.(data);
        } catch (e) {
          onError?.(e);
        }
      })();
    },
    [apiRequest]
  );

  return {
    acceptPartial,
  };
}
