import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import style from './Input.module.css';
import Icon, { ICONS } from '../Icon/Icon';
import Button, { COLOUR } from '../Button/Button';

export default function Input({
  className,
  onChange,
  placeholder,
  leftIcon,
  type,
  value,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const onClick = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    setShowPassword((prev) => !prev);
  };

  const finalType = useMemo(() => {
    if (type === 'password') {
      return showPassword ? 'text' : 'password';
    }

    return type;
  }, [showPassword, type]);

  return (
    <div className={clsx(style.container, className)}>
      {!!leftIcon && <Icon name={leftIcon} />}
      <input
        size={value?.length || 1}
        className={style.input}
        type={finalType}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
      {type === 'password' && (
        <Button colour={COLOUR.transparent} onClick={onClick}>
          <Icon name={ICONS.showPassword} />
        </Button>
      )}
    </div>
  );
}
