import styles from './NoPermission.module.css';

export default function NoPermission() {
  return (
    <div className={styles.container}>
      <p className={styles.paragraph}>
        No Permission to Access this Resource !
      </p>
    </div>
  );
}
