import { useCallback, useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import { env } from '../../utils/env';

const socketUrl = new URL(
  '/bets/claimed/notification',
  env.REACT_APP_PUBLIC_WS_URL || 'ws://localhost:8081'
);

function extractData(result) {
  let data;
  const messageType = result.result?.messageType;
  switch (messageType) {
    case 'heartbeat':
      break;
    case 'data':
      data = result.result?.response;
      break;
    case 'system_message':
      break;
    default:
      console.log('Unsupported message type: '.concat(messageType));
  }
  return data;
}

/**
 *
 * @param {boolean} enabled
 * @param {function(bet, string): void} onAdd
 * @param {function(bet, string): void} onRemove
 */
export default function useClaimedNotificationFeed(enabled, onAdd, onRemove) {
  const shouldReconnect = useCallback(() => enabled, [enabled]);

  const { lastMessage } = useWebSocket(
    socketUrl,
    {
      shouldReconnect,
    },
    enabled
  );

  useEffect(() => {
    if (lastMessage !== null) {
      const result = JSON.parse(lastMessage.data);

      const data = extractData(result);
      if (data !== undefined) {
        const action = data.action ?? '';
        const bet = data.bet ?? {};
        const email = data.email ?? '';

        if (action === 'add' && !!onAdd) onAdd(bet, email);
        if (action === 'remove' && !!onRemove) onRemove(bet, email);
      }
    }
  }, [lastMessage]);
}
