import { useCallback } from 'react';
import useRequest from '../useRequest';
import { env } from '../../utils/env';

const urlAccept = new URL(
  '/srv.action.Action/Accept',
  env.REACT_APP_PUBLIC_API_URL || 'http://localhost:8087'
);

const urlReject = new URL(
  '/srv.action.Action/Reject',
  env.REACT_APP_PUBLIC_API_URL || 'http://localhost:8087'
);

export class AcceptRejectRequest {
  constructor(betId, source, action) {
    this.betId = betId;
    this.source = source;
    this.action = action;
  }

  intoPayload() {
    return {
      bet: {
        betId: this.betId,
        source: this.source,
      },
    };
  }
}

export default function useAcceptRejectBet() {
  const { apiRequest } = useRequest();

  const acceptReject = useCallback(
    (request, onSuccess = undefined, onError = undefined) => {
      (async () => {
        try {
          const res = await apiRequest(
            request.action === 'accept' ? urlAccept : urlReject,
            'POST',
            request.intoPayload()
          );

          const data = await res.json();

          if (res.status !== 200) {
            onError?.(new Error(data.message));
          }

          onSuccess?.(data);
        } catch (e) {
          onError?.(e);
        }
      })();
    },
    [useRequest]
  );

  return {
    acceptReject,
  };
}
