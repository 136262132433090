import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { currencyFormat } from '../../utils/format';
import {
  RollPriceRequest,
  useRollPrice,
} from '../../hooks/selections/useRollPrice';
import style from './ClaimedRowChild.module.css';
import Button, { COLOUR } from '../Button/Button';
import Input from '../Input/Input';
import useAcceptPartialBet, {
  AcceptPartialRequest,
} from '../../hooks/bets/useAcceptPartialBet';
import useAcceptRejectBet, {
  AcceptRejectRequest,
} from '../../hooks/bets/useAcceptRejectBet';
import usePriceSet from '../../hooks/bets/usePriceSet';
import useClientComments from '../../hooks/comments/useClientComments';

function isPriceRollable({
  bet_type_full_name: betType,
  event_type: eventType,
}) {
  return (betType === 'win' || betType === 'place') && eventType === 'racing';
}

export const ClaimedRowChildCurry = (onError) =>
  function ClaimedRowChild({ bet }) {
    const { getPrice, setPrice } = usePriceSet();
    const {
      loading: cloading,
      error: cerror,
      comments,
    } = useClientComments(bet);

    const persistedPrice = useMemo(() => getPrice(bet), [getPrice]);

    const { rollPrice } = useRollPrice();
    const { acceptPartial } = useAcceptPartialBet();
    const { acceptReject } = useAcceptRejectBet();

    const [toWin, setToWin] = useState(-1);
    const [stake, setStake] = useState(-1);

    const [isRejectDisabled, setIsRejectDisabled] = useState(false);
    const [isAcceptDisabled, setIsAcceptDisabled] = useState(false);

    const formattedOnError = useCallback(
      (actionFailed) => (error) => {
        onError(`Failed to ${actionFailed}\n${error}`);
      },
      [onError]
    );

    useEffect(() => {
      setToWin((p) => (p < 0 ? bet.bet_adjusted_liability : p));
    }, [bet]);

    useEffect(() => {
      setStake((p) => (p < 0 ? bet.stake : p));
    }, [bet]);

    const clickRollPrice = (up) => (ev) => {
      ev.preventDefault();
      ev.stopPropagation();

      rollPrice(
        new RollPriceRequest(
          bet.source,
          bet.selection_id,
          up,
          bet.bet_type_full_name,
          bet.event_type
        ),
        ({ new_price: newPrice }) => {
          setPrice(bet, newPrice);
        },
        formattedOnError('roll price')
      );
    };

    const clickAcceptPartialBet = (ev, amount) => {
      ev.preventDefault();
      ev.stopPropagation();

      const displayAmount = Number(amount);

      if (Number.isNaN(displayAmount)) {
        formattedOnError('accept partial bet')(
          `Cannot accept partial bet - ${amount} is an invalid amount`
        );

        return;
      }

      const newLiability = amount * bet.bet_price - amount;
      const confirmMessage = `Confirm partial stake, $${currencyFormat(
        amount
      )} of $${currencyFormat(
        bet.stake
      )} for a new liability of $${currencyFormat(newLiability)}`;

      if (window.confirm(confirmMessage))
        acceptPartial(
          new AcceptPartialRequest(bet.ext_id, bet.source, amount),
          undefined,
          formattedOnError('accept partial bet')
        );
    };

    const claimAcceptRejectClick = (ev, action) => {
      if (action === 'accept') {
        setIsAcceptDisabled(true);
      } else if (action === 'reject') {
        setIsRejectDisabled(true);
      }

      ev.stopPropagation();
      ev.preventDefault();

      acceptReject(
        new AcceptRejectRequest(bet.ext_id, bet.source, action),
        undefined,
        formattedOnError(`${action} bet`)
      );
    };

    useEffect(() => {
      if (!!cerror) formattedOnError('get comments')(cerror);
    }, [cerror, formattedOnError]);

    const commentDisplay = useMemo(
      () =>
        comments.map(
          ({
            commenter,
            content,
            comment_id: commentId,
            last_updated: lastUpdated,
          }) => (
            <div key={`cc-${commentId}`} className={style['comment-container']}>
              <div className={style['comment-details']}>
                <span className={style.commenter}>{commenter}</span>
                <span>{dayjs(lastUpdated).format('DD/MM/YYYY HH:mm')}</span>
              </div>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: content }}
                className={style['comment-content']}
              />
            </div>
          )
        ),
      [comments]
    );

    const handleWinChange = (event) => setToWin(event.target.value);
    const handleStakeChange = (event) => setStake(event.target.value);

    const [isCommentsDisplayed, setIsCommentsDisplayed] = useState(false);

    const { color, label } = useMemo(() => {
      if (cloading) {
        return {
          color: COLOUR.white,
          label: 'Loading Comments...',
        };
      }

      if (cerror) {
        return {
          color: COLOUR.red,
          label: 'Error, no comments!',
        };
      }

      return {
        color: isCommentsDisplayed ? COLOUR.grey : COLOUR.black,
        label: `Comments (${comments.length})`,
      };
    }, [cloading, cerror, comments, isCommentsDisplayed]);

    return (
      <div className={style.container}>
        <div className={style['comments-section']}>
          <Button
            className={style['comments-button']}
            colour={color}
            onClick={() => setIsCommentsDisplayed((prev) => !prev)}
          >
            <div>{label}</div>
          </Button>
          <div className={style.comments}>
            {isCommentsDisplayed && commentDisplay}
          </div>
        </div>

        <div className={style['claimed-action-container']}>
          <div className={style['stake-container']}>
            <Button
              className={style['stake-btn']}
              onClick={(e) => clickAcceptPartialBet(e, stake)}
            >
              Stake
            </Button>
            <Input
              className={style['stake-input']}
              onChange={handleStakeChange}
              value={stake}
            />
          </div>
          <div className={style['win-container']}>
            <Button
              className={style['win-btn']}
              onClick={(e) =>
                clickAcceptPartialBet(e, toWin / (bet.bet_price - 1))
              }
            >
              Win
            </Button>
            <Input
              className={style['win-input']}
              onChange={handleWinChange}
              value={toWin}
            />
          </div>
          {bet.bet_adjusted_liability >= 100 && (
            <div className={style['to-win-container']}>
              <label>To Win</label>
              <Button
                className={style['money-btn']}
                onClick={(e) =>
                  clickAcceptPartialBet(e, 100 / (bet.bet_price - 1))
                }
              >
                $100
              </Button>

              {bet.bet_adjusted_liability >= 200 && (
                <Button
                  className={style['money-btn']}
                  onClick={(e) =>
                    clickAcceptPartialBet(e, 200 / (bet.bet_price - 1))
                  }
                >
                  $200
                </Button>
              )}
              {bet.bet_adjusted_liability >= 500 && (
                <Button
                  className={style['money-btn']}
                  onClick={(e) =>
                    clickAcceptPartialBet(e, 500 / (bet.bet_price - 1))
                  }
                >
                  $500
                </Button>
              )}
              {bet.bet_adjusted_liability >= 1000 && (
                <Button
                  className={style['money-btn']}
                  onClick={(e) =>
                    clickAcceptPartialBet(e, 1000 / (bet.bet_price - 1))
                  }
                >
                  $1000
                </Button>
              )}
              {bet.bet_adjusted_liability >= 2000 && (
                <Button
                  className={style['money-btn']}
                  onClick={(e) =>
                    clickAcceptPartialBet(e, 2000 / (bet.bet_price - 1))
                  }
                >
                  $2000
                </Button>
              )}
            </div>
          )}
          {isPriceRollable(bet) && (
            <div className={style['price-roll-container']}>
              <Button
                className={style['up-btn']}
                colour={COLOUR.grey}
                onClick={clickRollPrice(false)}
              >
                Down
              </Button>
              <span className={style['price-roll-input']}>
                {persistedPrice ?? bet.bet_price}
              </span>
              <Button
                className={style['up-btn']}
                colour={COLOUR.grey}
                onClick={clickRollPrice(true)}
              >
                Up
              </Button>
            </div>
          )}
          <Button
            disabled={isRejectDisabled}
            className={style['reject-btn']}
            colour={COLOUR.red}
            onClick={(e) => claimAcceptRejectClick(e, 'reject')}
          >
            Reject
          </Button>
          <Button
            disabled={isAcceptDisabled}
            className={style['accept-btn']}
            colour={COLOUR.green}
            onClick={(e) => claimAcceptRejectClick(e, 'accept')}
          >
            Accept
          </Button>
        </div>
      </div>
    );
  };
