import React, { useEffect, useRef, useState } from 'react';
import * as Tone from 'tone';
import styles from './GrpNav.module.css';
import Clock from '../Clock/Clock';
import useAuth from '../../hooks/auth/useAuth';
import ConditionalLink from '../ConditionalLink/ConditionalLink';
import Icon, { ICONS } from '../Icon/Icon';
import { env } from '../../utils/env';
import Button from '../Button/Button';
import useUnclaimedBetsAvailability from '../../hooks/bets/useUnclaimedBetsAvailability';
import useUser from '../../hooks/user/useUser';

const ISENABLED_BET_REFERRAL_TAB =
  env.REACT_APP_BET_REFERRAL_TAB_ENABLED === 'true' || false;
const ISENABLED_ADMIN_TAB = env.REACT_APP_ADMIN_TAB_ENABLED === 'true' || false;
const VERSION_HASH = env.REACT_APP_GRP_VERSION;

function GrpNav() {
  const unclaimedBetsAvailability = useUnclaimedBetsAvailability();
  const [mute, setMute] = useState(true);
  const { checkPermissions } = useUser();

  const synth = useRef(null);
  const looper = useRef(null);

  const toggleMute = () => {
    if (mute) {
      Tone.start();
    } else {
      Tone.Transport.stop();
    }
    setMute(!mute);
  };

  useEffect(() => {
    synth.current = new Tone.Synth().toDestination();
    looper.current = new Tone.Loop((time) => {
      synth.current.triggerAttackRelease('A5', '8n', time);
      synth.current.triggerAttackRelease('G5', '8n', time + 0.125);
      synth.current.triggerAttackRelease('D6', '8n', time + 0.25);
    }, 5);
  }, []);

  const { isSignedIn, logOut } = useAuth();
  const userLogout = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (isSignedIn) {
      logOut();
    }
  };

  useEffect(() => {
    if (checkPermissions('pages', 'referral')) {
      if (unclaimedBetsAvailability) {
        looper.current.start(0);
        Tone.Transport.start();
        return;
      }

      looper.current.stop();
      Tone.Transport.stop();
    }
  }, [unclaimedBetsAvailability]);

  return (
    <header className={styles.root}>
      <div className={styles.container}>
        <Icon name={ICONS.title} />
        <ConditionalLink to="/" condition={isSignedIn}>
          Bet Monitor
        </ConditionalLink>
        <ConditionalLink
          to="/referral"
          condition={
            isSignedIn &&
            ISENABLED_BET_REFERRAL_TAB &&
            checkPermissions('pages', 'referral')
          }
          pop={unclaimedBetsAvailability}
        >
          Bet Referral
        </ConditionalLink>
        <ConditionalLink
          to="/admin"
          condition={
            isSignedIn &&
            ISENABLED_ADMIN_TAB &&
            checkPermissions('pages', 'admin')
          }
        >
          Admin
        </ConditionalLink>
        {/* <ConditionalLink to="/login" condition={!isSignedIn}> */}
        {/*  Login */}
        {/* </ConditionalLink> */}
        <div className={styles['right-pane']}>
          <span>Version: {VERSION_HASH}</span>
          <Clock />
          {isSignedIn && (
            <Button onClick={userLogout}>
              <Icon name={ICONS.logout} />
            </Button>
          )}

          <Button
            onClick={() => {
              toggleMute();
            }}
          >
            <Icon name={mute ? ICONS.volumeMute : ICONS.volumeHigh} />
          </Button>
        </div>
      </div>
    </header>
  );
}

export default GrpNav;
