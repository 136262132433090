import { useCallback } from 'react';
import useAuth from './auth/useAuth';

export default function useRequest() {
  const { getToken } = useAuth();

  const apiRequest = useCallback(
    async (url, methodName, payload) => {
      const token = await getToken();
      if (!!token) {
        const authorization = `Bearer ${token}`;
        const jsonBody = JSON.stringify(payload);
        const requestOptions = {
          method: methodName,
          headers: {
            'Content-Type': 'application/json',
            Authorization: authorization,
          },
          body: jsonBody,
        };

        return fetch(url, requestOptions);
      }

      return undefined;
    },
    [getToken]
  );

  return { apiRequest };
}
