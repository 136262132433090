import { useCallback, useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { env } from '../../utils/env';
import useAuth from '../auth/useAuth';

const socketUrl = new URL(
  '/bets/history',
  env.REACT_APP_PUBLIC_WS_URL || 'ws://localhost:8081'
);

function extractData(result) {
  let data;
  const messageType = result.result?.messageType;
  switch (messageType) {
    case 'heartbeat':
      break;
    case 'data':
      data = result.result?.response;
      break;
    case 'authorization':
      break;
    case 'system_message':
      break;
    default:
      console.log('Unsupported message type: '.concat(messageType));
  }
  return data;
}

/**
 * manages stream of bets - used for history, referred, and claimed
 */
export default function useBetFeed(criteria, enabled) {
  const [message, setMessage] = useState(undefined);
  const { getToken } = useAuth();
  const shouldReconnect = useCallback(() => enabled, [enabled]);

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    socketUrl,
    {
      shouldReconnect,
    },
    enabled
  );

  useEffect(() => {
    if (lastMessage !== null) {
      const result = JSON.parse(lastMessage.data);

      const data = extractData(result);
      if (data !== undefined) {
        setMessage(data);
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    (async () => {
      if (readyState === ReadyState.OPEN) {
        const request = { request: criteria };
        request.token = `Bearer ${await getToken()}`;
        sendMessage(JSON.stringify(request), false);
      }
    })();
  }, [criteria, readyState, getToken]);

  return {
    message,
  };
}
