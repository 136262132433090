import React, { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import BetsTable from '../../components/BetsTable/BetsTable';
import PagingFooter from '../../components/PagingFooter/PagingFooter';
import useBetFeed from '../../hooks/bets/useBetFeed';
import useBets from '../../hooks/bets/useBets';
import SourceControls from '../../components/SourceControls/SourceControls';
import Separator from '../../components/Separator/Separator';
import style from './History.module.css';

function genObjFromPair(k, v) {
  const obj = {};

  obj[k] = v;

  return obj;
}

function revolveToggleOrder(prev) {
  switch (prev) {
    case 'off':
      return 'up';
    case 'up':
      return 'down';
    case 'down':
      return 'off';
    default:
      return undefined;
  }
}

function History() {
  const [sortMap, setSortMap] = useState(
    new Map([
      ['bet_date', 'down'],
      ['event', 'off'],
      ['event_location', 'off'],
      ['event_selection', 'off'],
      ['stake', 'off'],
      ['client', 'off'],
      ['bet_price', 'off'],
      ['bet_adjusted_liability', 'off'],
    ])
  );

  const changeSort = (field) => {
    setSortMap((prev) => {
      const next = new Map(Array.from(prev.keys()).map((k) => [k, 'off']));

      const nextOrder = revolveToggleOrder(prev.get(field));

      if (nextOrder === 'off') {
        if (field === 'bet_date') next.set('bet_date', 'up');
        else next.set('bet_date', 'down');
      } else next.set(field, nextOrder);

      return next;
    });
  };

  const onSortChange = (field) => (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    changeSort(field);
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [criteria, setCriteria] = useState({
    sources: ['empty'],
    event_code: '',
    bet_type: '',
    event: '',
    event_types: [],
    selection: '',
    status: '',
    stake: 0,
    event_location: '',
    client: '',
    tags: [],
    start_date: dayjs().subtract(1, 'day').format(), // .format('YYYY-MM-DD HH:mm:ss'),
    end_date: dayjs().format(), // .format('YYYY-MM-DD HH:mm:ss'),
    paging: {
      page_number: 1,
      page_size: 100,
    },
    sorting: {
      field: 'bet_date',
      ascending: false,
    },
  });

  const [fireBets, setFireBets] = useState(true);

  const runBetsQueryID = useRef(0);

  const setCriteriaWithTime = useCallback(
    (update) => {
      setCriteria((prev) => ({
        ...prev,
        ...update,
        ...(pageNumber === 1
          ? {
              start_date: dayjs().subtract(1, 'day').format(),
              end_date: dayjs().format(),
            }
          : {}),
      }));
    },
    [pageNumber]
  );

  // fire bets query
  useEffect(() => {
    runBetsQueryID.current = setTimeout(() => {
      setFireBets(true);
    }, 500);

    return () => {
      clearTimeout(runBetsQueryID.current);
    };
  }, [criteria]);

  const onSourceControlChange = useCallback(
    ({ type, sources, tags }) => {
      setCriteriaWithTime({ event_types: type, sources, tags });
    },
    [setCriteriaWithTime]
  );

  const onTableControlChange = (k, v) => {
    if (criteria[k] !== undefined) setCriteriaWithTime(genObjFromPair(k, v));
  };

  const { bets, loading, paging } = useBets(criteria, fireBets);

  const [isFeedOn, setIsFeedOn] = useState(true);

  const onFeedControlChange = (isActive) => {
    setIsFeedOn(isActive);

    if (isActive) {
      setSortMap((prevSortMap) => {
        const next = new Map(
          Array.from(prevSortMap.keys()).map((k) => [k, 'off'])
        );

        next.set('bet_date', 'down');

        return next;
      });

      setFireBets(true);
    }
  };

  useEffect(() => {
    const [field, state] = Array.from(sortMap.entries()).find(
      (kv) => kv[1] !== 'off'
    );

    onTableControlChange('sorting', {
      field,
      ascending: state === 'up',
    });
  }, [sortMap]);

  const { message } = useBetFeed(criteria, isFeedOn && pageNumber === 1);

  const [consolidatedBets, setConsolidatedBets] = useState([]);

  useEffect(() => {
    const isSortingByTimeDesc =
      criteria.sorting.field === 'bet_date' &&
      criteria.sorting.ascending === false;

    setIsFeedOn(isSortingByTimeDesc);
  }, [criteria]);

  useEffect(() => {
    setConsolidatedBets(bets);
  }, [bets]);

  useEffect(() => {
    if (!!message)
      setConsolidatedBets((prev) => {
        const cb = [message, ...prev];

        // sort cb on time and take one element off of top if its over 100
        if (cb.length > 100) cb.pop();

        return cb;
      });
  }, [message]);

  useEffect(() => {
    if (loading) setFireBets(false);
  }, [loading, setFireBets]);

  // if page number changes, update criteria with new page number
  useEffect(() => {
    setCriteriaWithTime({
      paging: { page_number: pageNumber, page_size: 100 },
    });
  }, [pageNumber, setCriteriaWithTime]);

  return (
    <div className={style.container}>
      <SourceControls
        onChange={onSourceControlChange}
        onFeedControlChange={onFeedControlChange}
        feedControlEnabled={pageNumber === 1}
        forceFeedIsPaused={!isFeedOn}
        page="history"
      />
      <Separator />
      <BetsTable
        tbodyClassName={style['table-body']}
        bets={consolidatedBets}
        maxSize={100}
        onChange={onTableControlChange}
        placeholder={
          <div className={style['table-placeholder']}>No Results Found.</div>
        }
        sortMap={sortMap}
        onSortChange={onSortChange}
        page="history"
      />
      <PagingFooter
        totalCount={paging.total_pages}
        currentPage={pageNumber}
        onPageChange={(n, e) => {
          e.stopPropagation();
          e.preventDefault();

          setPageNumber(n);
        }}
      />
    </div>
  );
}

export default History;
