import { useEffect, useState } from 'react';
import { env } from '../../utils/env';
import useRequest from '../useRequest';
import useUser from '../user/useUser';

// const TEMPLATE = process.env.REACT_APP_SOURCE_IMG_TEMPLATE ?? "https://betmakers.com/img/circle_logos/SOURCE_CODE_DST.png";

export const buildSourceLogo = (code) =>
  process.env.REACT_APP_SOURCE_IMG_TEMPLATE.replace('SOURCE_CODE_DST', code);

const url = new URL(
  '/srv.support.Source/GetEnabledSources',
  env.REACT_APP_PUBLIC_API_URL || 'http://localhost:8087'
);

export default function useSources() {
  const [loading, setLoading] = useState(false);
  const [sources, setSources] = useState([]);
  const [error, setError] = useState(undefined);
  const [refetch, setRefetch] = useState(false);

  const { apiRequest } = useRequest();
  const { checkPermissions } = useUser();

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const res = await apiRequest(url, 'POST', {});

        const data = await res.json();

        if ((data.sources ?? []).length < 1 || res.status !== 200)
          setError(new Error(res.message));
        else {
          setSources(
            data.sources
              .filter(({ code }) => checkPermissions('sources', code))
              .map((s) => ({ ...s, logo: buildSourceLogo(s.code) }))
          );
        }
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [refetch, apiRequest]);

  return {
    sources,
    loading,
    error,
    refetch: () => setRefetch((p) => !p),
  };
}
