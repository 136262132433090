import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import style from './ConditionalLink.module.css';

export default function ConditionalLink({
  children,
  condition,
  to,
  className,
  pop = false,
  ...props
}) {
  return !!condition && !!to ? (
    <Link
      to={to}
      className={clsx(
        style.link,
        window.location.pathname === to && style.selected,
        pop ? style['link-pop'] : style['link-no-pop'],
        className
      )}
      {...props}
    >
      {children}
    </Link>
  ) : (
    <div />
  );
}
