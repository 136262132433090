import { Route, Routes } from 'react-router-dom';
import useUser from '../../hooks/user/useUser';
import {
  Health,
  Login,
  PasswordReset,
  Main,
  Referral,
  Test,
} from '../../pages';
import NoPermission from '../NoPermission/NoPermission';
import History from '../../pages/History/History';

export function RoutesProvider() {
  const { checkPermissions } = useUser();

  return (
    <Routes>
      <Route path="/" element={<Main />}>
        <Route path="" element={<History />} />
        <Route
          path="referral"
          element={
            checkPermissions('pages', 'referral') ? (
              <Referral />
            ) : (
              <NoPermission />
            )
          }
        />
      </Route>
      <Route path="healthz" element={<Health />} />
      <Route path="login" element={<Login />} />
      <Route path="password-reset" element={<PasswordReset />} />
      <Route path="test" element={<Test />} />
    </Routes>
  );
}
