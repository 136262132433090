import { useState } from 'react';
import style from './UnclaimedRowChild.module.css';
import Button, { COLOUR } from '../Button/Button';
import useClaimBet, { ClaimBetRequest } from '../../hooks/bets/useClaimBet';

export const UnclaimedRowChildCurry = (onError) =>
  function UnclaimedRowChild({ bet }) {
    const { claim } = useClaimBet();
    const [isDisabled, setIsDisabled] = useState(false);

    const onClaim = (ev) => {
      setIsDisabled(true);
      ev.preventDefault();
      ev.stopPropagation();

      claim(new ClaimBetRequest(bet.source, bet.ext_id), undefined, onError);
    };

    return (
      <div className={style['action-container']}>
        <Button
          onClick={onClaim}
          className={style['claim-btn']}
          colour={COLOUR.black}
          disabled={isDisabled}
        >
          Claim
        </Button>
      </div>
    );
  };
