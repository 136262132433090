import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import style from './SourceControls.module.css';
import Button, { COLOUR } from '../Button/Button';
import useSources from '../../hooks/sources/useSources';
import Dropdown from '../Dropdown/Dropdown';
import useTags from '../../hooks/tags/useTags';
import MonitorControls from '../MonitorControls/MonitorControls';
import Checkbox from '../Checkbox/Checkbox';
import Icon, { ICONS } from '../Icon/Icon';
import Input from '../Input/Input';
import useUser from '../../hooks/user/useUser';

export default function SourceControls({
  onChange = () => console.log('No onChange applied'),
  feedControlEnabled = true,
  onFeedControlChange = () => console.log('No onFeedControlChange applied'),
  forceFeedIsPaused = false,
  page = 'history',
}) {
  const {
    user,
    updateRemotePreferences,
    updateLocalPreferences,
    localPreferences,
  } = useUser();

  const [areRemotePreferencesLoaded, setAreRemotePreferencesLoaded] =
    useState(false);
  const [areLocalPreferencesLoaded, setAreLocalPreferencesLoaded] =
    useState(false);
  const remotePreferences = useMemo(
    () => JSON.parse(user?.preferences || '{}'),
    [user]
  );

  const { sources, sourceError } = useSources();
  const { tags, tagsError } = useTags();

  const [controls, setControls] = useState({
    type: ['all'],
    sources: [],
    tags: [],
  });

  const [selectedSources, setSelectedSources] = useState(new Map());
  const [selectedTags, setSelectedTags] = useState(new Map());
  const [selectedEventType, setSelectedEventType] = useState('all');

  useEffect(() => {
    if (areLocalPreferencesLoaded) return;

    const pagePreferences = localPreferences[page] ?? {};

    const localTags = pagePreferences.tags ?? {};

    const tagsEntryList = Array.from(Object.keys(localTags));

    const newSelectedTags = new Map(tagsEntryList.map((t) => [t, true]));

    setSelectedTags(newSelectedTags);

    setAreLocalPreferencesLoaded(true);
  }, [localPreferences, areLocalPreferencesLoaded]);

  useEffect(() => {
    if (!areLocalPreferencesLoaded) return;

    updateLocalPreferences(page, {
      tags: Array.from(selectedTags).reduce((prev, [k, v]) => {
        const next = prev;
        next[k] = v;
        return next;
      }, {}),
    });
  }, [selectedTags, areLocalPreferencesLoaded]);

  useEffect(() => {
    if (!areRemotePreferencesLoaded && !!user) {
      const remoteSelectedSources = new Map(
        (remotePreferences[page]?.sources ?? []).map((s) => [s, true])
      );

      setSelectedSources(remoteSelectedSources);

      const rawType = remotePreferences[page]?.type;

      const type =
        !!rawType && typeof rawType === typeof [''] ? rawType : ['all'];

      setSelectedEventType(type[0]);

      setAreRemotePreferencesLoaded(true);
    }
  }, [areRemotePreferencesLoaded, remotePreferences, page, user]);

  useEffect(() => {
    if (sourceError) {
      alert(sourceError);
      return;
    }

    if (tagsError) alert(tagsError);
  }, [sourceError, tagsError]);

  useEffect(() => {
    setControls((prev) => ({
      ...prev,
      type: [selectedEventType],
    }));
  }, [selectedEventType]);

  useEffect(() => {
    setControls((prev) => ({
      ...prev,
      sources: Array.from(selectedSources.keys()),
    }));
  }, [selectedSources]);

  useEffect(() => {
    setControls((prev) => ({
      ...prev,
      tags: Array.from(selectedTags.keys()),
    }));
  }, [selectedTags]);

  const onTypeChange = (newValue) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedEventType(newValue);
  };

  const onSourceSelect = (code) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedSources((selected) => {
      if (selected.has(code)) selected.delete(code);
      else selected.set(code, true);

      return new Map(selected);
    });
  };

  const onTagSelect = (color) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedTags((selected) => {
      if (selected.has(color)) selected.delete(color);
      else selected.set(color, true);

      return new Map(selected);
    });
  };

  const prioritySourceToggles = useMemo(
    () =>
      // first 6 for now, later will use some setting and look for certain sources to prioritise
      sources.slice(0, 6).map(({ code, logo }) => (
        <Button
          key={`${code}-pr`}
          className={clsx(
            style['source-btn'],
            selectedSources.has(code) && style['source-btn-on']
          )}
          onClick={onSourceSelect(code)}
        >
          <Checkbox
            size="sm"
            state={selectedSources.has(code) ? 'on' : 'off'}
          />
          <img alt={code} className={style['source-logo']} src={logo} />
        </Button>
      )),
    [selectedSources, sources]
  );

  const [sourceSearchValue, setSourceSearchValue] = useState('');

  const onSourceSearch = (ev) => {
    const { value } = ev.target;
    setSourceSearchValue(value);
  };

  const dropdownSourceToggles = useMemo(
    () =>
      sources
        .filter(
          ({ name }) =>
            !!name.toLowerCase().match(sourceSearchValue.toLowerCase())
        )
        .map(({ code, name, logo }) => (
          <Button
            key={`${code}-${name}-dd`}
            className={clsx(style['dropdown-item'])}
            onClick={onSourceSelect(code)}
          >
            <div className={style['source-desc']}>
              <img
                alt={code}
                className={style['dropdown-source-logo']}
                src={logo}
              />
              <span>{name}</span>
            </div>

            <Checkbox
              size="lg"
              state={selectedSources.has(code) ? 'on' : 'off'}
            />
          </Button>
        )),
    [selectedSources, sources, sourceSearchValue]
  );

  const tagToggles = useMemo(() => {
    const filteredTags = tags.filter((t) => t.enabled);

    return filteredTags.length === 0 ? (
      <div>No Tags Found</div>
    ) : (
      filteredTags.map((t) => (
        <Button
          key={t.color}
          className={style['dropdown-item']}
          onClick={onTagSelect(t.color)}
        >
          <div className={style['tag-desc']}>
            <span
              className={style['color-dot']}
              style={{ backgroundColor: t.color }}
            />
            <span>{t.color.charAt(0).toUpperCase() + t.color.slice(1)}</span>
          </div>
          <Checkbox
            size="lg"
            state={selectedTags.has(t.color) ? 'on' : 'off'}
            className={style.checkbox}
          />
        </Button>
      ))
    );
  }, [selectedTags, tags]);

  const [isAllSources, setIsAllSources] = useState(false);

  useEffect(() => {
    const selectedSourcesLength = Array.from(selectedSources.keys()).length;
    const check = selectedSourcesLength === sources.length;

    setIsAllSources(check);
  }, [selectedSources, sources]);

  const onSelectAllSources = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (!isAllSources)
      setSelectedSources((prevSources) => {
        sources.forEach(({ code }) => prevSources.set(code, true));
        return new Map(prevSources);
      });
    else
      setSelectedSources((prevSources) => {
        prevSources.clear();
        return new Map(prevSources);
      });
  };

  useEffect(() => {
    if (typeof onChange === typeof Function) {
      onChange(controls);

      if (!areRemotePreferencesLoaded) return;

      updateRemotePreferences(page, {
        type: controls.type,
        sources: controls.sources,
      });
    }
  }, [controls, onChange]);

  return (
    <div className={style.container}>
      <div className={style['filter-container']}>
        <Button
          className={style['event-type-btn']}
          onClick={onTypeChange('all')}
          colour={selectedEventType === 'all' && COLOUR.black}
        >
          All
        </Button>
        <Button
          className={style['event-type-btn']}
          onClick={onTypeChange('racing')}
          colour={selectedEventType === 'racing' && COLOUR.black}
        >
          Racing
        </Button>
        <Button
          className={style['event-type-btn']}
          onClick={onTypeChange('sports')}
          colour={selectedEventType === 'sports' && COLOUR.black}
        >
          Sports
        </Button>

        <Dropdown
          label={
            <>
              <span>Tags</span>
              <Icon name={ICONS.downwardPointer} />
            </>
          }
          buttonClassName={style['btn-with-icon']}
        >
          <div className={style['dropdown-menu']}>{tagToggles}</div>
        </Dropdown>

        {prioritySourceToggles}

        <Dropdown
          label={
            <>
              <span>View All</span>
              <span className={style['highlighted-count']}>
                {sources.length}
              </span>
            </>
          }
          buttonClassName={style['source-dropdown-btn']}
        >
          <div
            className={clsx(
              style['dropdown-menu'],
              style['source-dropdown-menu']
            )}
          >
            <Input
              className={style['source-search-field']}
              placeholder="Search"
              onChange={onSourceSearch}
              leftIcon={ICONS.search}
            />
            <Button
              className={clsx(
                style['dropdown-item'],
                style['dropdown-source-select-all']
              )}
              onClick={onSelectAllSources}
            >
              <div>
                <span>{isAllSources ? 'Clear All' : 'Select All'}</span>
              </div>

              <Checkbox size="lg" state={isAllSources ? 'on' : 'off'} />
            </Button>
            {dropdownSourceToggles}
          </div>
        </Dropdown>
      </div>

      <div>
        {feedControlEnabled && (
          <MonitorControls
            onChange={onFeedControlChange}
            forceIsPaused={forceFeedIsPaused}
          />
        )}
      </div>
    </div>
  );
}
