import { env } from '../../utils/env';

export const SAVE_PREFERENCES_URL = new URL(
  '/srv.user.Users/SaveUserPreferences',
  env.REACT_APP_PUBLIC_API_URL || 'http://localhost:8087'
);

export const GET_CURRENT_USER_URL = new URL(
  '/srv.user.Users/GetCurrentUser',
  env.REACT_APP_PUBLIC_API_URL || 'http://localhost:8087'
);
