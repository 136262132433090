import { useCallback, useMemo, useState } from 'react';

export default function useLocalStorage(key) {
  const [refresh, setRefresh] = useState({});

  const item = useMemo(() => localStorage.getItem(key), [key, refresh]);
  const setter = useCallback(
    (value) => {
      localStorage.setItem(key, value);
      setRefresh({});
    },
    [key]
  );

  const remove = useCallback(() => {
    localStorage.removeItem(key);
    setRefresh({});
  }, [key]);

  return {
    item,
    setter,
    remove,
  };
}
