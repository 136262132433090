import { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import useBetFeed from './useBetFeed';
import useReferredBets from './useReferredBets';
import useUser from '../user/useUser';
import useReferredNotificationFeed from './useReferredNotificationFeed';

export default function useUnclaimedBetsAvailability() {
  const [unclaimedBetsAvailability, setUnclaimedBetsAvailability] =
    useState(false);
  const [unclaimedBets, setUnclaimedBets] = useState([]);

  // Default settings.
  const [criteria, setCriteria] = useState({
    sources: [],
    event: '',
    event_types: ['all'],
    selection: '',
    status: 'pending',
    stake: 0,
    event_location: '',
    client: '',
    tags: [],
    start_date: dayjs().subtract(1, 'day').format(), // end_date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    paging: {
      page_number: 1,
      page_size: 100,
    },
  });

  const { user } = useUser();
  const remotePreferences = useMemo(
    () => JSON.parse(user?.preferences || '{}'),
    [user]
  );

  const [fireBets, setFireBets] = useState(false);
  const runBetsQueryID = useRef(0);

  const { message } = useBetFeed(criteria, fireBets);
  const { bets } = useReferredBets(criteria, fireBets);

  useEffect(() => {
    setCriteria((prev) => ({
      ...prev,
      ...{
        sources: remotePreferences?.referred?.sources ?? [],
        event_types: remotePreferences?.referred?.type ?? [],
      },
    }));
  }, [remotePreferences]);

  // fire bets query.
  useEffect(() => {
    runBetsQueryID.current = setTimeout(() => {
      setFireBets(true);
    }, 500);

    return () => {
      clearTimeout(runBetsQueryID.current);
    };
  }, [criteria]);

  // Set referred bets on mount.
  useEffect(() => {
    setUnclaimedBets(bets);
  }, [bets]);

  // Removes the actioned and claimed bets.
  useReferredNotificationFeed(true, (bet) => {
    setUnclaimedBets((prev) => prev.filter((b) => b.id !== bet.id));
  });

  // Listen to incoming stuff.
  useEffect(() => {
    if (!!message) {
      setUnclaimedBets((prev) => [message, ...prev]);
    }
  }, [message]);

  // Sets the availability flag.
  useEffect(() => {
    setUnclaimedBetsAvailability(!!unclaimedBets.length);
  }, [unclaimedBets]);

  return unclaimedBetsAvailability;
}
