import { useCallback } from 'react';
import useRequest from '../useRequest';
import { env } from '../../utils/env';

const url = new URL(
  '/srv.bet_service.BetService/ClaimBet',
  env.REACT_APP_PUBLIC_API_URL || 'http://localhost:8087'
);

export class ClaimBetRequest {
  constructor(source, betId) {
    this.source = source;
    this.betId = betId;
  }

  intoPayload() {
    return {
      source: this.source,
      betId: this.betId,
    };
  }
}

export default function useClaimBet() {
  const { apiRequest } = useRequest();

  const claim = useCallback(
    (request, onSuccess, onError) => {
      (async () => {
        try {
          const res = await apiRequest(url, 'POST', request.intoPayload());

          const data = await res.json();

          if (res.status !== 200) onError?.(new Error(data.message));
          else onSuccess?.(data);
        } catch (e) {
          onError?.(e);
        }
      })();
    },
    [apiRequest]
  );

  return {
    claim,
  };
}
