import React, { forwardRef } from 'react';
import clsx from 'clsx';
import styles from './Button.module.css';

export const COLOUR = {
  pink: 'color-pink',
  green: 'color-green',
  blue: 'color-blue',
  black: 'color-black',
  grey: 'color-grey',
  white: 'color-white',
  red: 'color-red',
  babyPooYellow: 'baby-poo-yellow',
  transparent: 'transparent',
};

/**
 *
 * @param {[JSX.Element]} children
 * @param {(MouseEvent) => void} onClick
 * @param {string | undefined} colour
 * @param {string} className
 * @param {ForwardedRef<HTMLButtonElement>} ref
 */
const Button = forwardRef(
  (
    { children, onClick, colour = COLOUR.white, className = '', ...rest },
    ref
  ) => (
    <button
      type="button"
      onClick={onClick}
      className={clsx(styles.btn, styles[colour], className)}
      ref={ref}
      {...rest}
    >
      {children}
    </button>
  )
);

export default Button;
