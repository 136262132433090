import React from 'react';

export default class Health extends React.Component {
  constructor(props) {
    super(props);

    console.log(props);
  }

  render() {
    return 'Healthy';
  }
}
