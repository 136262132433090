import React, { useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/auth/useAuth';
import Input from '../../components/Input/Input';
import Button, { COLOUR } from '../../components/Button/Button';

import style from './Login.module.css';
import { ICONS } from '../../components/Icon/Icon';

function Login() {
  const { logIn, logInWithGoogle } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onLogin = (e) => {
    e.preventDefault();
    logIn(email, password);
  };

  const gLogin = (e) => {
    e.preventDefault();
    logInWithGoogle();
  };

  return (
    <div className={style.container}>
      <div className={style.card}>
        <div className={style.form}>
          <span className={style['form-title']}>Log In</span>

          <span className={style['form-intro']}>
            Welcome, please enter your details
          </span>

          <span className={style['form-label']}>Email</span>
          <Input
            leftIcon={ICONS.email}
            className={style['form-input']}
            onChange={(ev) => setEmail(ev.target.value)}
          />

          <span className={style['form-label']}>Password</span>
          <Input
            type="password"
            className={style['form-input']}
            onChange={(ev) => setPassword(ev.target.value)}
          />

          <Button
            className={clsx(style.btn)}
            colour={COLOUR.black}
            onClick={onLogin}
          >
            Log in
          </Button>

          <span className={style['sso-label']}>
            Sign in with your corporate ID
          </span>
          <Button
            className={clsx(style.btn, style['sso-btn'])}
            onClick={gLogin}
          >
            Betmakers SSO
          </Button>
          <Link to="/password-reset">Forgot your password?</Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
