import { useEffect, useState } from 'react';
import useRequest from '../useRequest';
import { env } from '../../utils/env';

const url = new URL(
  '/srv.bet_service.BetService/GetClaimedBets',
  env.REACT_APP_PUBLIC_API_URL || 'http://localhost:8087'
);

export default function useGetClaimedBets(
  getClaimedBetsCriteria,
  fireGetClaimedBets = true
) {
  const [loading, setLoading] = useState(false);
  const [bets, setBets] = useState([]);
  const [error, setError] = useState(undefined);
  const [refetchSwitch, setRefetchSwitch] = useState(false);

  const refetch = () => {
    setRefetchSwitch((p) => !p);
  };

  const { apiRequest } = useRequest();

  useEffect(() => {
    (async () => {
      if (fireGetClaimedBets) {
        setLoading(true);

        try {
          const res = await apiRequest(url, 'POST', getClaimedBetsCriteria);

          const data = await res.json();

          if (res.status !== 200) {
            setError(new Error(data.message));
            return;
          }
          setBets(data.bets ?? []);
        } catch (e) {
          setError(e);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [
    refetchSwitch,
    getClaimedBetsCriteria,
    fireGetClaimedBets,
    useRequest,
    apiRequest,
  ]);

  return {
    bets,
    loading,
    error,
    refetch,
  };
}
