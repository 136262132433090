import { useEffect, useState } from 'react';
import useRequest from '../useRequest';
import { env } from '../../utils/env';

const url = new URL(
  '/srv.bet_service.BetService/GetBets',
  env.REACT_APP_PUBLIC_API_URL || 'http://localhost:8087'
);

export default function useBets(criteria, fire = true) {
  const [loading, setLoading] = useState(false);
  const [bets, setBets] = useState([]);
  const [paging, setPaging] = useState({
    total_pages: 1,
  });
  const [error, setError] = useState(undefined);
  const [refetchSwitch, setRefetchSwitch] = useState(false);

  const refetch = () => {
    setRefetchSwitch((p) => !p);
  };

  const { apiRequest } = useRequest();

  useEffect(() => {
    (async () => {
      if (fire) {
        setLoading(true);

        try {
          const res = await apiRequest(url, 'POST', criteria);

          const data = await res.json();

          if (res.status !== 200) {
            setError(new Error(data.message));
            return;
          }

          setBets(data.bets ?? []);
          setPaging(data.paging ?? { total_pages: 1 });
        } catch (e) {
          setError(e);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [refetchSwitch, criteria, fire, useRequest, apiRequest]);

  return {
    bets,
    paging,
    loading,
    error,
    refetch,
  };
}
