import React, { useCallback, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';
import style from './Dropdown.module.css';
import Button from '../Button/Button';
import useOnUnfocus from '../../hooks/useOnUnfocus';

export default function Dropdown({
  label,
  children,
  buttonClassName,
  containerClassName,
  buttonColor,
}) {
  const [initialRender, setInitialRender] = useState(false);
  const [displayMenu, setDisplayMenu] = useState(false);
  const buttonRefElement = useRef(null);
  const popperElement = useRef(null);

  useOnUnfocus(popperElement, () => setDisplayMenu(false), displayMenu);

  const { styles, attributes } = usePopper(
    buttonRefElement.current,
    popperElement.current,
    { placement: 'bottom-start' }
  );

  useEffect(() => {
    if (displayMenu && !initialRender) setInitialRender(true);
  }, [initialRender, displayMenu]);

  const switchDisplay = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setDisplayMenu((p) => !p);
    },
    [setDisplayMenu]
  );

  useOnUnfocus(popperElement, () => setDisplayMenu(false));

  return (
    <div className={containerClassName}>
      <Button
        className={buttonClassName}
        ref={buttonRefElement}
        onClick={switchDisplay}
        colour={buttonColor}
      >
        {label}
      </Button>

      {displayMenu &&
        createPortal(
          <div
            ref={popperElement}
            className={style.tooltip}
            style={styles.popper}
            {...attributes.popper}
          >
            {children}
          </div>,
          document.querySelector('#root') || document.body
        )}
    </div>
  );
}
