import { gt, round } from 'lodash';

export function currencyFormat(num) {
  return gt(num, 0) ? round(num, 2).toFixed(2) : num;
}

export function amountFormat(amount) {
  if (amount > 0) {
    return currencyFormat(amount);
  }
  return 'N/A';
}

export function originalStakeFormat(originalStake, stake) {
  if (originalStake === stake) {
    return currencyFormat(stake);
  }
  return `${currencyFormat(stake)} (${currencyFormat(originalStake)})`;
}

export function bonusBetFormat(isBonusBet, stake) {
  if (isBonusBet) {
    return 'BB: '.concat(currencyFormat(stake));
  }
  return currencyFormat(stake);
}

export function thousandsFormat(num) {
  const thousandth = num / 1000;

  return `${round(thousandth, thousandth < 100 ? 1 : 0)}k`;
}
