import React from 'react';
import { Outlet } from 'react-router-dom';
import Login from './Login/Login';
import useAuth from '../hooks/auth/useAuth';
import GrpNav from '../components/GrpNav/GrpNav';
import Separator from '../components/Separator/Separator';
import useUser from '../hooks/user/useUser';

function Main() {
  const { currentUser } = useAuth();
  const { loading, error, user: authUser } = currentUser();
  const { user } = useUser();

  if (loading) return <div>Loading...</div>; // TODO: loading thingy

  if (error) return <div>Error...</div>; // TODO: error page?

  if (!loading && !authUser)
    return (
      <>
        <GrpNav />
        <Login />
      </>
    );

  if (!user) return <div>Loading...</div>;

  if (!user.active) return <div>User is not active :(</div>; // TODO: is not active page ?

  if (!loading && authUser)
    return (
      <>
        <GrpNav />
        <Separator />
        <Outlet />
      </>
    );
}

export default Main;
