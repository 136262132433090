import { useCallback } from 'react';
import useRequest from '../useRequest';
import { env } from '../../utils/env';

const url = new URL(
  '/srv.action.Action/RollPrice',
  env.REACT_APP_PUBLIC_API_URL || 'http://localhost:8087'
);

export class RollPriceRequest {
  constructor(source, selectionId, up, betType, type) {
    this.source = source;
    this.selectionId = selectionId.toString();
    this.up = up;
    this.betType = betType;
    this.type = type;
  }

  intoPayload() {
    return {
      type: this.type,
      bet_type: this.type === 'sport' ? 'place' : this.betType,
      direction: this.up ? 'up' : 'down',
      selection: {
        selection_id: this.selectionId,
        source: this.source,
      },
    };
  }
}

export function useRollPrice() {
  const { apiRequest } = useRequest();

  const rollPrice = useCallback(
    (request, onSuccess = undefined, onError = undefined) => {
      (async () => {
        try {
          const res = await apiRequest(url, 'POST', request.intoPayload());

          const data = await res.json();

          if (res.status !== 200 || !data.ok) {
            onError?.(new Error(data.message));
          } else onSuccess?.(data);
        } catch (e) {
          onError?.(e);
        }
      })();
    },
    [apiRequest]
  );

  return {
    rollPrice,
  };
}
