import { useEffect, useState } from 'react';
import { env } from '../../utils/env';
import useRequest from '../useRequest';

const url = new URL(
  '/srv.support.Tag/GetTags',
  env.REACT_APP_PUBLIC_API_URL || 'http://localhost:8087'
);

export default function useTags() {
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(undefined);
  const [refetch, setRefetch] = useState(false);

  const { apiRequest } = useRequest();

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const res = await apiRequest(url, 'POST', {});

        const data = await res.json();

        if ((data.tags ?? []).length < 1 || res.status !== 200) {
          setError(new Error('No Tags Found!'));
        } else setTags(data.tags);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [refetch, apiRequest]);

  return {
    tags,
    loading,
    error,
    refetch: () => setRefetch((p) => !p),
  };
}
