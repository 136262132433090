import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import style from './Clock.module.css';

export default function Clock({ className = '' }) {
  const [time, setTime] = useState(dayjs());

  useEffect(() => {
    const id = window.setInterval(
      // () => setTime(prev => prev.add(1, "second")),
      () => setTime(dayjs()),
      1000,
    );

    return () => clearInterval(id);
  }, []);

  return (
    <div className={`${className} ${style.container}`}>
      <div className={`${style.day} ${style.item}`}>{time.format('ddd')}</div>
      <div className={`${style.time} ${style.item}`}>
        {time.format('HH:mm:ss')}
      </div>
    </div>
  );
}
