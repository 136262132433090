import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import Color from 'color';
import clsx from 'clsx';
import style from './BetsTable.module.css';
import Input from '../Input/Input';
import useSources, { buildSourceLogo } from '../../hooks/sources/useSources';
import {
  amountFormat,
  bonusBetFormat,
  originalStakeFormat,
  currencyFormat,
  thousandsFormat,
} from '../../utils/format';
import SliderHeaderCell from '../SliderHeaderCell/SliderHeaderCell';
import { SortToggle } from '../SortToggle/SortToggle';
import useUser from '../../hooks/user/useUser';

function contrastingTextColor(color) {
  const bc = color.contrast(Color('black'));
  const wc = color.contrast(Color('white'));

  return bc > wc ? 'black' : 'white';
}

function constructRiskClientURL({ client_id: clientId }, riskHost) {
  if (!riskHost) return undefined;

  return new URL(`/clients/${clientId}`, `https://${riskHost}`);
}

function constructRiskMultiClientURL({ risk_bet_id: riskBetId }, riskHost) {
  if (!riskHost) return undefined;

  return new URL(`/multi-bets/${riskBetId}`, `https://${riskHost}`);
}

function constructRiskEventURL(
  {
    competition_id: competitionId,
    event_id: eventId,
    event_type: eventType,
    market_id: marketId,
  },
  riskHost
) {
  if (!riskHost) return undefined;

  switch (eventType) {
    case 'racing':
      return new URL(
        `/meetings/${competitionId}/races/${eventId}`,
        `https://${riskHost}`
      );
    case 'sports':
      return new URL(
        `/sports?event_id=${eventId}&current_view=Market&market_id=${marketId}`,
        `https://${riskHost}`
      );
    default:
      console.log(`Unknown event type ${eventType}`);
      return undefined;
  }
}

function newColumnSize(w, mw) {
  return {
    width: w ?? 64,
    minWidth: mw ?? 10,
  };
}

function sanitizeInputValues(original) {
  const sanitizedStake = +original.stake;

  return {
    ...original,
    stake: Number.isNaN(sanitizedStake) ? 0 : sanitizedStake,
  };
}

/**
 * @param {[]} bets
 * @param RowChild
 * @param {number} maxSize
 * @param {function(string, any): void} onChange
 * @param {string} tbodyClassName
 * @param {JSXElement | undefined} placeholder
 * @param {boolean} searchEnabled
 * @param {function(field): function(MouseEvent): void} onSortChange
 * @param {Map<string, string>} sortMap
 * @param {string} page
 * @returns {JSX.Element}
 */
export default function BetsTable({
  bets = [],
  RowChild,
  maxSize = -1,
  onChange = () => {
    console.log('onChange not set');
  },
  tableContainerClassName = '',
  tbodyClassName = '',
  placeholder = undefined,
  searchEnabled = true,
  onSortChange,
  sortMap,
  page,
}) {
  const { sources } = useSources();

  const { updateLocalPreferences, localPreferences } = useUser();

  const sourceMap = useMemo(
    () => new Map(sources.map((s) => [s.code, s.risk_url])),
    [sources]
  );

  const [inputValues, setInputValues] = useState({
    bet_type: '',
    event_code: '',
    client: '',
    event_location: '',
    event: '',
    selection: '',
    stake: '',
  });

  const [arePreferencesLoaded, setArePreferencesLoaded] = useState(false);

  useEffect(() => {
    setInputValues((prev) => ({
      ...prev,
      ...(localPreferences[page]?.inputValues ?? {}),
    }));

    setArePreferencesLoaded(true);
  }, [localPreferences]);

  useEffect(() => {
    if (!arePreferencesLoaded) return;

    updateLocalPreferences(page, {
      inputValues,
    });

    const newValueList = Array.from(
      Object.entries(sanitizeInputValues(inputValues))
    );

    newValueList.forEach((pair) => {
      onChange(pair[0], pair[1]);
    });
  }, [page, inputValues]);

  const [columnSizes, setColumnSizes] = useState({
    time: newColumnSize(64, 46),
    source: newColumnSize(58, 46),
    id: newColumnSize(248, 46),
    client: newColumnSize(272, 46),
    event_code: newColumnSize(75, 46),
    venue: newColumnSize(128, 46),
    event: newColumnSize(224, 46),
    selection: newColumnSize(240, 46),
    stake: newColumnSize(88, 46),
    bet_type: newColumnSize(75, 46),
    winloss: newColumnSize(128, 46),
    liability: newColumnSize(80, 46),
    product: newColumnSize(72, 46),
    price: newColumnSize(72, 46),
    avg: newColumnSize(72, 46),
    bets: newColumnSize(72, 46),
  });

  const onColumnSizeChange = (leftKey) => (leftDiff) => {
    setColumnSizes((prev) => {
      const out = {};

      const { width: leftWidth, minWidth: leftMinWidth } = prev[leftKey];

      let calcLeftWidth = leftWidth + leftDiff;

      if (calcLeftWidth < leftMinWidth) {
        calcLeftWidth = leftMinWidth;
      }

      out[leftKey] = {
        width: calcLeftWidth,
        minWidth: leftMinWidth,
      };

      return { ...prev, ...out };
    });
  };

  const searchChange = (key) => (ev) => {
    const { value } = ev.target;

    setInputValues((prev) => {
      const obj = { ...prev };
      obj[key] = value;

      return obj;
    });
  };

  const searchChangeWithValidNumber = (key) => (ev) => {
    const value = +ev.target.value;
    setInputValues((prev) => {
      const obj = { ...prev };
      obj[key] = value;

      return obj;
    });
  };

  const onControlClick = (k, v) => (e) => {
    if (window.event.ctrlKey || window.event.metaKey) {
      e.preventDefault();
      e.stopPropagation();

      setInputValues((prev) => {
        const obj = { ...prev };
        obj[k] = v;

        return obj;
      });
    }
  };

  const onClientClick = useCallback(
    (bet, k, v) => (e) => {
      if (!window.event.ctrlKey && !window.event.metaKey) {
        const url = constructRiskClientURL(bet, sourceMap.get(bet.source));

        if (!!url) window.open(url, '_blank', 'width=600');
      } else {
        onControlClick(k, v)(e);
      }
    },
    [sourceMap]
  );

  const onEventClick = useCallback(
    (bet, k, v) => (e) => {
      if (!window.event.ctrlKey && !window.event.metaKey) {
        const url = constructRiskEventURL(bet, sourceMap.get(bet.source));

        if (!!url) window.open(url, '_blank', 'width=600');
      } else {
        onControlClick(k, v)(e);
      }
    },
    [sourceMap]
  );

  const onSelectionClick = useCallback(
    (bet, k, v) => (e) => {
      if (
        !window.event.ctrlKey &&
        !window.event.metaKey &&
        bet.bet_type === 'Multi'
      ) {
        const url = constructRiskMultiClientURL(bet, sourceMap.get(bet.source));

        if (!!url) window.open(url, '_blank', 'width=600');
      } else {
        onControlClick(k, v)(e);
      }
    },
    [sourceMap]
  );

  const rows = useMemo(() => {
    const filteredBets = maxSize >= 0 ? bets.slice(0, maxSize) : bets;

    return filteredBets.map((b) => (
      <React.Fragment key={`${b.id}-br`}>
        <tr
          className={clsx(
            style.row,
            b.status === 'rejected' && style['row-color-red'],
            b.is_live_bet && style['row-color-blue']
          )}
        >
          <td>{dayjs(b.bet_date).format('HH:mm:ss')}</td>
          <SliderHeaderCell
            onChange={onColumnSizeChange('time', 'source')}
            isHeader={false}
            className={style['column-resizer']}
          />
          <td title={b.source}>
            <img
              alt={b.source}
              className={style['source-logo']}
              src={buildSourceLogo(b.source)}
              style={{ position: 'relative' }}
            />
          </td>
          <SliderHeaderCell
            onChange={onColumnSizeChange('source', 'id')}
            isHeader={false}
            className={style['column-resizer']}
          />
          <td title={b.ext_id}>{b.ext_id}</td>
          <SliderHeaderCell
            onChange={onColumnSizeChange('id', 'client')}
            isHeader={false}
            className={style['column-resizer']}
            style={
              b.tag !== ''
                ? {
                    backgroundColor: b.tag,
                    color: contrastingTextColor(Color(b.tag)),
                  }
                : {}
            }
          />
          <td
            className={style.clickable}
            style={
              b.tag !== ''
                ? {
                    backgroundColor: b.tag,
                    color: contrastingTextColor(Color(b.tag)),
                  }
                : {}
            }
            title={b.client}
            onClick={onClientClick(b, 'client', b.client)}
          >
            {b.client}
          </td>
          <SliderHeaderCell
            onChange={onColumnSizeChange('client', 'event_code')}
            isHeader={false}
            className={style['column-resizer']}
          />
          <td
            title={b.event_code}
            onClick={onControlClick('event_code', b.event_code)}
          >
            {b.event_code}
          </td>
          <SliderHeaderCell
            onChange={onColumnSizeChange('event_code', 'venue')}
            isHeader={false}
            className={style['column-resizer']}
          />
          <td
            title={b.event_location}
            onClick={onControlClick('event_location', b.event_location)}
          >
            {b.event_location}
          </td>
          <SliderHeaderCell
            onChange={onColumnSizeChange('venue', 'event')}
            isHeader={false}
            className={style['column-resizer']}
          />
          <td
            className={clsx(style.clickable, style['event-field-capitalize'])}
            title={b.event}
            onClick={onEventClick(b, 'event', b.event)}
          >
            {b.event}
          </td>
          <SliderHeaderCell
            onChange={onColumnSizeChange('event', 'selection')}
            isHeader={false}
            className={style['column-resizer']}
          />
          <td
            title={b.event_selection}
            onClick={onSelectionClick(b, 'selection', b.event_selection)}
          >
            {b.event_selection}
          </td>
          <SliderHeaderCell
            onChange={onColumnSizeChange('selection', 'stake')}
            isHeader={false}
            className={clsx(
              b.is_bonus_bet && style['bonus-bet'],
              style['column-resizer']
            )}
          />
          <td
            className={clsx(b.is_bonus_bet && style['bonus-bet'])}
            onClick={onControlClick('stake', b.stake)}
          >
            {b.original_stake > 0
              ? originalStakeFormat(b.original_stake, b.stake)
              : bonusBetFormat(b.is_bonus_bet, b.stake)}
          </td>
          <SliderHeaderCell
            onChange={onColumnSizeChange('stake', 'bet_type')}
            isHeader={false}
            className={style['column-resizer']}
          />
          <td
            title={b.bet_type}
            onClick={onControlClick('bet_type', b.bet_type)}
          >
            {b.bet_type}
          </td>
          <SliderHeaderCell
            onChange={onColumnSizeChange('bet_type', 'product')}
            isHeader={false}
            className={style['column-resizer']}
          />
          <td title={b.bet_product}>{b.bet_product}</td>
          <SliderHeaderCell
            onChange={onColumnSizeChange('product', 'price')}
            isHeader={false}
            className={style['column-resizer']}
          />
          <td>{amountFormat(b.bet_price)}</td>
          <SliderHeaderCell
            onChange={onColumnSizeChange('price', 'avg')}
            isHeader={false}
            className={style['column-resizer']}
          />
          <td>{currencyFormat(b.bet_average)}</td>
          <SliderHeaderCell
            onChange={onColumnSizeChange('avg', 'bets')}
            isHeader={false}
            className={style['column-resizer']}
          />
          <td>{b.bet_count}</td>
          <SliderHeaderCell
            onChange={onColumnSizeChange('bets', 'winloss')}
            isHeader={false}
            className={style['column-resizer']}
          />
          <td
            className={clsx(
              b.bet_win_loss_percentage < 0
                ? style['win-loss-negative']
                : style['win-loss-positive']
            )}
          >
            {thousandsFormat(b.bet_win_loss_amount)} (
            {b.bet_win_loss_percentage}
            %)
          </td>
          <SliderHeaderCell
            onChange={onColumnSizeChange('winloss', 'liability')}
            isHeader={false}
            className={style['column-resizer']}
          />
          <td>{amountFormat(b.bet_adjusted_liability)}</td>
        </tr>
        {!!RowChild && (
          <tr>
            <td colSpan={31}>
              <RowChild bet={b} />
            </td>
          </tr>
        )}
      </React.Fragment>
    ));
  }, [bets, RowChild, maxSize]);

  return (
    <div className={clsx(style.container, tableContainerClassName)}>
      <table className={style.main}>
        <thead>
          <tr className={style['header-row']}>
            <th
              scope="col"
              data-title="time"
              style={{ width: `${columnSizes.time.width}px` }}
            >
              <SortToggle
                className={style['sort-btn']}
                state={sortMap?.get('bet_date')}
                onClick={onSortChange && onSortChange('bet_date')}
                label="Time"
              />
            </th>
            <SliderHeaderCell
              onChange={onColumnSizeChange('time', 'source')}
              className={style['column-resizer']}
            />
            <th
              scope="col"
              data-title="source"
              style={{ width: `${columnSizes.source.width}px` }}
            >
              <span>Source</span>
            </th>
            <SliderHeaderCell
              onChange={onColumnSizeChange('source', 'id')}
              className={style['column-resizer']}
            />
            <th
              scope="col"
              data-title="id"
              style={{ width: `${columnSizes.id.width}px` }}
            >
              <span>ID</span>
            </th>
            <SliderHeaderCell
              onChange={onColumnSizeChange('id', 'client')}
              className={style['column-resizer']}
            />
            <th
              scope="col"
              data-title="client"
              style={{ width: `${columnSizes.client.width}px` }}
            >
              <SortToggle
                className={style['sort-btn']}
                state={sortMap?.get('client')}
                onClick={onSortChange && onSortChange('client')}
                label="Client"
              />
              {searchEnabled && (
                <Input
                  onChange={searchChange('client')}
                  className={style['search-field']}
                  value={inputValues.client}
                />
              )}
            </th>
            <SliderHeaderCell
              onChange={onColumnSizeChange('client', 'event_code')}
              className={style['column-resizer']}
            />
            <th
              scope="col"
              data-title="event_code"
              style={{ width: `${columnSizes.event_code.width}px` }}
            >
              <SortToggle
                className={style['sort-btn']}
                state={sortMap?.get('event_code')}
                onClick={onSortChange && onSortChange('event_code')}
                label="Code"
              />
              {searchEnabled && (
                <Input
                  onChange={searchChange('event_code')}
                  className={style['search-field']}
                  value={inputValues.event_code}
                />
              )}
            </th>
            <SliderHeaderCell
              onChange={onColumnSizeChange('event_code', 'venue')}
              className={style['column-resizer']}
            />
            <th
              scope="col"
              data-title="venue"
              style={{ width: `${columnSizes.venue.width}px` }}
            >
              <SortToggle
                className={style['sort-btn']}
                state={sortMap?.get('event_location')}
                onClick={onSortChange && onSortChange('event_location')}
                label="Venue"
              />
              {searchEnabled && (
                <Input
                  onChange={searchChange('event_location')}
                  className={style['search-field']}
                  value={inputValues.event_location}
                />
              )}
            </th>
            <SliderHeaderCell
              onChange={onColumnSizeChange('venue', 'event')}
              className={style['column-resizer']}
            />
            <th
              scope="col"
              data-title="event"
              style={{ width: `${columnSizes.event.width}px` }}
            >
              <SortToggle
                className={style['sort-btn']}
                state={sortMap?.get('event')}
                onClick={onSortChange && onSortChange('event')}
                label="Event"
              />
              {searchEnabled && (
                <Input
                  onChange={searchChange('event')}
                  className={style['search-field']}
                  value={inputValues.event}
                />
              )}
            </th>
            <SliderHeaderCell
              onChange={onColumnSizeChange('event', 'selection')}
              className={style['column-resizer']}
            />
            <th
              scope="col"
              data-title="selection"
              style={{ width: `${columnSizes.selection.width}px` }}
            >
              <SortToggle
                className={style['sort-btn']}
                state={sortMap?.get('event_selection')}
                onClick={onSortChange && onSortChange('event_selection')}
                label="Selection"
              />
              {searchEnabled && (
                <Input
                  onChange={searchChange('selection')}
                  className={style['search-field']}
                  value={inputValues.selection}
                />
              )}
            </th>
            <SliderHeaderCell
              onChange={onColumnSizeChange('selection', 'stake')}
              className={style['column-resizer']}
            />
            <th
              scope="col"
              data-title="stake"
              style={{ width: `${columnSizes.stake.width}px` }}
            >
              <SortToggle
                className={style['sort-btn']}
                state={sortMap?.get('stake')}
                onClick={onSortChange && onSortChange('stake')}
                label="Stake"
              />
              {searchEnabled && (
                <Input
                  onChange={searchChangeWithValidNumber('stake')}
                  className={style['search-field']}
                  value={inputValues.stake}
                />
              )}
            </th>
            <SliderHeaderCell
              onChange={onColumnSizeChange('stake', 'bet_type')}
              className={style['column-resizer']}
            />
            <th
              scope="col"
              data-title="bet_type"
              style={{ width: `${columnSizes.bet_type.width}px` }}
            >
              <SortToggle
                className={style['sort-btn']}
                state={sortMap?.get('bet_type')}
                onClick={onSortChange && onSortChange('bet_type')}
                label="BT"
              />
              {searchEnabled && (
                <Input
                  onChange={searchChange('bet_type')}
                  className={style['search-field']}
                  value={inputValues.bet_type}
                />
              )}
            </th>
            <SliderHeaderCell
              onChange={onColumnSizeChange('bet_type', 'product')}
              className={style['column-resizer']}
            />
            <th
              scope="col"
              data-title="product"
              style={{ width: `${columnSizes.product.width}px` }}
            >
              <span>Product</span>
            </th>
            <SliderHeaderCell
              onChange={onColumnSizeChange('product', 'price')}
              className={style['column-resizer']}
            />
            <th
              scope="col"
              data-title="price"
              style={{ width: `${columnSizes.price.width}px` }}
            >
              <SortToggle
                className={style['sort-btn']}
                state={sortMap?.get('bet_price')}
                onClick={onSortChange && onSortChange('bet_price')}
                label="Price"
              />
            </th>
            <SliderHeaderCell
              onChange={onColumnSizeChange('price', 'avg')}
              className={style['column-resizer']}
            />
            <th
              scope="col"
              data-title="avg"
              style={{ width: `${columnSizes.avg.width}px` }}
            >
              <span>Avg</span>
            </th>
            <SliderHeaderCell
              onChange={onColumnSizeChange('avg', 'bets')}
              className={style['column-resizer']}
            />
            <th
              scope="col"
              data-title="bets"
              style={{ width: `${columnSizes.bets.width}px` }}
            >
              <span>Bets</span>
            </th>
            <SliderHeaderCell
              onChange={onColumnSizeChange('bets', 'winloss')}
              className={style['column-resizer']}
            />
            <th
              scope="col"
              data-title="winloss"
              style={{ width: `${columnSizes.winloss.width}px` }}
            >
              <span>Win/Loss</span>
            </th>
            <SliderHeaderCell
              onChange={onColumnSizeChange('winloss', 'liability')}
              className={style['column-resizer']}
            />
            <th
              scope="col"
              data-title="liability"
              style={{ width: `${columnSizes.liability.width}px` }}
            >
              <SortToggle
                className={style['sort-btn']}
                state={sortMap?.get('bet_adjusted_liability')}
                onClick={onSortChange && onSortChange('bet_adjusted_liability')}
                label="Liability"
              />
            </th>
          </tr>
        </thead>
        <tbody className={clsx(tbodyClassName)}>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              <td colSpan={31}>{placeholder}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
