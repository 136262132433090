import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import Button, { COLOUR } from '../Button/Button';
import styles from './MonitorControls.module.css';
import Icon, { ICONS } from '../Icon/Icon';

export default function MonitorControls({
  onChange = () => console.log('MonitorControls onChange not applied'),
  forceIsPaused,
}) {
  const [isPaused, setIsPaused] = useState(false);

  useEffect(
    () => forceIsPaused !== undefined && setIsPaused(forceIsPaused),
    [forceIsPaused]
  );

  const handleClick = useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    setIsPaused((current) => {
      onChange(current);

      return !current;
    });
  });

  return (
    <div
      className={
        isPaused
          ? clsx(styles.container, styles['resume-container'])
          : clsx(styles.container, styles['pause-container'])
      }
    >
      <Button
        className={
          isPaused
            ? styles['monitor-btn']
            : clsx(styles['monitor-btn'], styles['pause-btn'])
        }
        onClick={handleClick}
        colour={isPaused ? COLOUR.black : COLOUR.grey}
      >
        <div className={styles['icon-margin ']}>
          <Icon name={isPaused ? ICONS.play : ICONS.pause} />
        </div>
        {isPaused ? 'Resume' : 'Pause'}
      </Button>
    </div>
  );
}
