import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './utils/reportWebVitals';

import { AuthProvider } from './contexts/auth/authContext';
import { UserProvider } from './contexts/user/UserContext';
import { RoutesProvider } from './components/RoutesProvider/RoutesProvider';

const container = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(container);

root.render(
  <Router>
    <AuthProvider>
      <UserProvider>
        <RoutesProvider />
      </UserProvider>
    </AuthProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
