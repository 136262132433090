import clsx from 'clsx';
import React from 'react';
import Button from '../Button/Button';
import Icon, { ICONS } from '../Icon/Icon';
import styles from './SortToggle.module.css';
import { checkFeature } from '../../utils/env';

export function SortToggle({ className, state, onClick, label }) {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {checkFeature('sort') && !!state ? (
        <Button className={clsx(styles.sortable)} onClick={onClick}>
          {label}
          <div className={clsx(styles['toggle-container'], className)}>
            <div className={styles['toggle-half']}>
              {(state === 'off' || state === 'up') && <Icon name={ICONS.asc} />}
            </div>
            <div className={styles['toggle-half']}>
              {(state === 'down' || state === 'off') && (
                <Icon name={ICONS.desc} />
              )}
            </div>
          </div>
        </Button>
      ) : (
        <span>{label}</span>
      )}
    </>
  );
}
