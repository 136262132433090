import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Runs onChange when mouse movements
 * @param {string} className cell className
 * @param {function(leftDiff: number, rightDiff: number)} onChange runs on dragging mouse movement
 * @param {boolean} isHeader th if true, td if not
 * @param style cell style
 * @return {JSX.Element}
 */
export default function SliderHeaderCell({
  className,
  onChange,
  isHeader = true,
  style,
}) {
  const [isDragging, setIsDragging] = useState(false);
  const x = useRef(0);

  const start = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsDragging(true);
    x.current = ev.screenX;

    console.log(x.current);
  };

  const end = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setIsDragging(false);
  };

  const move = useCallback(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();

      if (!isDragging) return;

      const diff = x.current - ev.screenX;

      onChange(-diff, diff);

      x.current = ev.screenX;
    },
    [isDragging]
  );

  useEffect(() => {
    document.addEventListener('mousemove', move);
    document.addEventListener('mouseup', end);

    return () => {
      document.removeEventListener('mousemove', move);
      document.removeEventListener('mouseup', end);
    };
  }, [move, end]);

  return isHeader ? (
    <th
      aria-label="slider-header-cell"
      className={className}
      onMouseDown={start}
      onTouchStart={start}
      style={style}
    />
  ) : (
    <td
      aria-label="slider-header-cell"
      className={className}
      onMouseDown={start}
      onTouchStart={start}
      style={style}
    />
  );
}
