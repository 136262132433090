import { useEffect } from 'react';

/**
 *
 * @param {React.RefObject} elementRef
 * @param {() => void} callback
 * @param {boolean} enabled
 */
export default function useOnUnfocus(elementRef, callback, enabled = true) {
  useEffect(() => {
    function onUnfocus(ev) {
      if (!elementRef?.current?.contains(ev.target) && enabled) callback();
    }

    document.addEventListener('click', onUnfocus);

    return () => {
      document.removeEventListener('click', onUnfocus);
    };
  });
}
