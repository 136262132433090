import { createContext, useCallback, useEffect, useMemo } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';

const STORAGE_KEY = 'price_set';

function createPriceKey({ ext_id: betId, source }) {
  return `${source}${betId}`;
}

export const PriceSetContext = createContext({
  getPrice: () => {},
  setPrice: () => {},
});

export function PriceSetProvider({ children, bets, purge = false }) {
  const { item: lsItem, setter: lsSetter } = useLocalStorage(STORAGE_KEY);

  const priceSet = useMemo(() => JSON.parse(lsItem ?? '{}'), [lsItem]);
  const betsMap = useMemo(
    () => new Map(bets.map((b) => [createPriceKey(b), true])),
    [bets]
  );

  useEffect(() => {
    if (purge) {
      const keysForDelete = Object.keys(priceSet).filter(
        (k) => !betsMap.get(k)
      );

      const newPriceSet = priceSet;

      keysForDelete.forEach((k) => delete newPriceSet[k]);

      lsSetter(JSON.stringify(newPriceSet));
    }
  }, [bets, purge, priceSet, lsSetter]);

  const getPrice = useCallback(
    (bet) => priceSet[createPriceKey(bet)],
    [priceSet]
  );

  const setPrice = useCallback(
    (bet, price) => {
      const newPriceSet = priceSet;

      newPriceSet[createPriceKey(bet)] = price;

      lsSetter(JSON.stringify(newPriceSet));
    },
    [priceSet, lsSetter]
  );

  const value = useMemo(() => ({ getPrice, setPrice }), [getPrice, setPrice]);

  return (
    <PriceSetContext.Provider value={value}>
      {children}
    </PriceSetContext.Provider>
  );
}
